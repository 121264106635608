import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Delhi Public School, Dwarka",
  address: "Dwarka, New Delhi",
  client: "DPS Society, New Delhi",
  area: "70,000 Sq. Ft.",
  project: "Expansion of DPS Dwarka, Auditorium cum Sports Complex, New Delhi",
  category: "Institutions | Architecture | School",
  status: "Under Construction",
  backlink: "/projects/institutions/",
};

export default class Projectdpsdwarka extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Institutional Projects | Delhi Public School, Dwarka"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the expansion of the DPS Dwarka campus with the addition of an auditorium and sports complex spanning over 70,000 sq.ft."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
